import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getArticles,
  getArticleById,
  getArticlesByCategory,
} from "api/articles.api";

export const getArticlesAsync = createAsyncThunk(
  "articles/get-all",
  async (category) => {
    const data = await getArticles(undefined, category);
    return data;
  }
);

export const getNextArticlesAsync = createAsyncThunk(
  "articles/get-all-next",
  async ({ last, category }) => {
    const data = await getArticles(last, category);
    return data;
  }
);

export const getArticleByIdAsync = createAsyncThunk(
  "article/get-by-id",
  async (id) => {
    const article = await getArticleById(id);
    let content = "";
    if (article.content) {
      const response = await fetch(article.content);
      const text = await response.text();
      content = response.status === 200 ? text : "";
    }
    return { ...article, content };
  }
);

export const getArticlesByCategoryAsync = createAsyncThunk(
  "article/get-by-category",
  async (category) => {
    const articles = await getArticlesByCategory(category);
    return articles;
  }
);
