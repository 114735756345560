import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import programsReducer from "redux-toolkit/programs/slice";
import articlesReducer from "redux-toolkit/articles/slice";

const combinedReducer = combineReducers({
  programs: programsReducer,
  articles: articlesReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
