import { createSlice } from "@reduxjs/toolkit";
import {
  getArticlesAsync,
  getArticleByIdAsync,
  getArticlesByCategoryAsync,
  getNextArticlesAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
  last: null,
  count: 0,
};

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticlesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getArticlesAsync.fulfilled, (state, action) => {
        state.all = action.payload.articles;
        state.last = action.payload.last;
        state.count = action.payload.totalArticlesCount;
        state.isLoading = false;
      })
      .addCase(getArticlesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getNextArticlesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNextArticlesAsync.fulfilled, (state, action) => {
        state.all = [...state.all, ...action.payload.articles];
        state.last = action.payload.last;
        state.isLoading = false;
      })
      .addCase(getNextArticlesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getArticleByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getArticleByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getArticleByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      })
      .addCase(getArticlesByCategoryAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getArticlesByCategoryAsync.fulfilled, (state, action) => {
        state.all = action.payload;
        state.isLoading = false;
      })
      .addCase(getArticlesByCategoryAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      });
  },
});

export default articlesSlice.reducer;
