import { createSlice } from "@reduxjs/toolkit";
import {
  getProgramsAsync,
  getProgramByIdAsync,
  getProgramsByCategoryAsync,
  getNextProgramsAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
  category: [],
  last: null,
  count: 0,
};

export const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProgramsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProgramsAsync.fulfilled, (state, action) => {
        state.all = action.payload.programs;
        state.last = action.payload.last;
        state.count = action.payload.totalProgramsCount;
        state.isLoading = false;
      })
      .addCase(getProgramsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getNextProgramsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNextProgramsAsync.fulfilled, (state, action) => {
        state.all = [...state.all, ...action.payload.programs];
        state.last = action.payload.last;
        state.isLoading = false;
      })
      .addCase(getNextProgramsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getProgramByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProgramByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getProgramByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      })
      .addCase(getProgramsByCategoryAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProgramsByCategoryAsync.fulfilled, (state, action) => {
        state.category = action.payload;
        state.isLoading = false;
      })
      .addCase(getProgramsByCategoryAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.category = [];
      });
  },
});

export default programsSlice.reducer;
